<template>
  <div class="popin" @click.self="cancel">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Popin',
  mounted() {
    window.addEventListener('keyup', this.cancel)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.cancel)
  },
  methods: {
    cancel(e) {
      if ((e.type === 'keyup' && e.keyCode === 27) || e.type === 'click')
        this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
.popin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
</style>
