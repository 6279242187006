<template>
  <b-page max-width class="configPage">
    <h1 class="e-h1">configuration</h1>
    <form action="" @submit.prevent="submit">
      <!-- <e-input
        class="configPage__input"
        label="Nom"
        name="name"
        placeholder="Nom du site"
        v-model="siteName"
      /> -->
      <e-textarea
        class="configPage__input"
        label="Nom"
        name="name"
        placeholder="Nom du site"
        v-model="siteName"
      />
      <e-input
        class="configPage__input"
        label="Lien Legal Notice"
        name="legal_notice_link"
        type="url"
        placeholder="Lien Legal Notice"
        v-model="legalNotice"
      />
      <e-input
        class="configPage__input"
        label="Lien vers le live"
        name="liveLink"
        type="url"
        placeholder="Lien vers le live"
        v-model="liveLink"
      />
      <e-checkbox
        type="checkbox"
        style="margin-bottom: 25px;"
        label="Menu visible"
        v-model="menuVisible"
        name="menuVisible"
      />
      <e-checkbox
        type="checkbox"
        style="margin-bottom: 25px;"
        label="Thème sombre"
        v-model="themeDark"
        name="themeDark"
      />
      <e-checkbox
        type="checkbox"
        style="margin-bottom: 25px;"
        label="Afficher le live"
        v-model="live"
        name="live"
      />
      <e-checkbox
        type="checkbox"
        label="Afficher le live en fullscreen"
        v-model="fullscreenLive"
        name="fullscreenLive"
      />
      <br />
      <e-button type="submit">Enregistrer</e-button>
    </form>
  </b-page>
</template>

<script>
import axiosAdmin from '../scripts/axios-admin'
export default {
  data() {
    return {
      legalNotice: null,
      live: null,
      liveLink: null,
      fullscreenLive: null,
      siteName: null,
      themeDark: false,
      menuVisible: false
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { data } = await axiosAdmin.get('/configurations')
    next(vm => {
      vm.legalNotice = data.legalNotice
      try {
        vm.live = data.live != '' ? JSON.parse(data.live) || 0 : 0
        vm.fullscreenLive =
          data.fullscreenLive != '' ? JSON.parse(data.fullscreenLive) || 0 : 0
        vm.liveLink = data.liveLink
      } catch (e) {
        vm.live = false
        vm.fullscreenLive = false
        vm.liveLink = ''
      }

      vm.themeDark = data.theme === 'dark'
      vm.menuVisible =
        data.menuVisible != '' ? JSON.parse(data.menuVisible) || 0 : 0

      vm.siteName = data.siteName.split('<br />').join('\n')
    })
  },
  methods: {
    async submit() {
      await axiosAdmin.post('/configurations', {
        siteName: this.siteName.replace(/\r?\n/g, '<br />'),
        live: JSON.parse(this.live),
        fullscreenLive: JSON.parse(this.fullscreenLive),
        liveLink: this.liveLink,
        legalNotice: this.legalNotice,
        theme: this.themeDark ? 'dark' : 'light',
        menuVisible: this.menuVisible
      })
      this.$store.dispatch('notifications/present', {
        type: 'Notice',
        text: 'La configuration à été mise à jour.'
      })
    }
  }
}
</script>

<style lang="scss">
.configPage {
  h1 {
    margin-bottom: 25px;
  }

  &__input {
    margin-bottom: 25px;
  }

  form {
    margin-bottom: 25px;
  }
}
</style>
