<template>
  <GridItem
    class="gridYoutube"
    :hoverable="false"
    :data="content"
    is-youtube
    :admin="admin"
  >
    <div
      :class="{ 'gridYoutube__wrapper--visible': loaded }"
      @transitionend.self="onTransitionEnd"
      class="gridYoutube__wrapper"
    >
      <!-- <div
        :class="{'gridYoutube__poster--visible': !playing }"
        class="gridYoutube__poster"
        @click="togglePlay"
      >
        <PlayIcon class="gridYoutube__posterPlay" />
        <img :src="content.media_poster" alt="" />
      </div> -->
      <youtube
        :video-id="videoId"
        :player-vars="playerVars"
        @playing="() => (playing = true)"
        ref="youtube"
        v-if="content.media.originFile.url.match(/live-youtube/gi)"
      />
      <template v-else>
        <video
          ref="video"
          :src="content.media.originFile.url"
          :muted="muted"
          autoplay
          playsinline
          @click.self="togglePlaystate"
          loop
        />
        <footer class="gridYoutube__footer">
          <button @click="() => (muted = !muted)">
            SOUND {{ muted ? 'OFF' : 'ON' }}
          </button>
          <p v-show="canDownload">
            <button ref="embed" :data-clipboard-text="embedUrl" href="#">
              {{ copied ? 'EMBED LINK COPIED' : 'COPY EMBED LINK' }}
            </button>
          </p>
        </footer>
      </template>
    </div>
  </GridItem>
</template>

<script>
import { mapState } from 'vuex'
import Clipboard from 'clipboard'
// import PlayIcon from "@/components/svg/play.svg";
import GridItemMixin from '@/components/mixins/grid-item'
import { youtubeParser } from '../../assets/scripts/youtube-parser'

export default {
  name: 'gridYoutube',
  mixins: [GridItemMixin],
  // components: {
  //   PlayIcon
  // },
  props: {
    admin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      playing: false,
      muted: true,
      copied: false,
      playerVars: {
        autoplay: 1
      },
      videoId: null
    }
  },
  computed: {
    ...mapState({
      canDownload: state => state.version.canDownload
    }),
    embedUrl() {
      const id = youtubeParser(this.content.media.url)
      return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    }
  },
  mounted() {
    this.videoId = youtubeParser(this.content.media.url)
    /* eslint-disable-next-line */
    if (typeof this.$refs.embed !== 'undefined') {
      const clip = new Clipboard(this.$refs.embed)
      clip.on('success', () => (this.copied = true))
    }

    if (this.$refs.video) {
      this.$refs.video.addEventListener('canplay', () => {
        setTimeout(() => {
          this.loaded = true
        }, 300)
      })
    } else if (this.$refs.youtube) {
      setTimeout(() => {
        this.loaded = true
      }, 300)
    }
  },
  methods: {
    onTransitionEnd() {
      this.$emit('youtube-ready')
    },
    togglePlaystate() {
      if (this.$refs.video.paused) this.$refs.youtube.play()
      else this.$refs.youtube.pause()
    }
  }
}
</script>

<style lang="scss">
.gridYoutube {
  $self: &;
  position: relative;
  cursor: inherit;

  &__poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 150ms linear;

    &--visible {
      opacity: 1;
    }

    &Play {
      position: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(2);
    }
  }

  &__wrapper {
    height: 100%;
    transition: opacity 250ms linear;
    opacity: 0;

    &--visible {
      opacity: 1;
    }
  }

  video {
    cursor: pointer;
  }

  &__footer {
    // visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 17px;
    opacity: 1;
    transition: opacity 250ms linear;
    color: white;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    // justify-content: space-between;

    @media (hover: hover) {
      opacity: 0;

      #{$self}:hover & {
        opacity: 1;
      }
    }

    p,
    a,
    button {
      color: inherit;
    }

    p {
      margin: 0 21px;

      a,
      button {
        text-decoration: underline;
      }
    }

    button {
      background: none;
      border: unset;
      padding: 0;
      font-size: inherit;
    }
  }
}
</style>
