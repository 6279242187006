var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-page',{staticClass:"pageVersions"},[_c('h1',{staticClass:"e-h1"},[_vm._v("Versions")]),(_vm.versions.length > 0)?_c('b-entity-list',{staticClass:"pageVersions__entryList",staticStyle:{"width":"100%"},attrs:{"columns":[
      'Nom',
      'Statut',
      'Publique',
      'Archive',
      'Press Release',
      'Actions'
    ]}},_vm._l((_vm.versions),function(version){return _c('tr',{key:version.id},[_c('td',[_vm._v(_vm._s(version.name))]),_c('td',[_vm._v(_vm._s(_vm.status(version)))]),_c('td',[_vm._v(_vm._s(version.public ? 'Oui' : 'Non'))]),_c('td',[_vm._v(_vm._s(version.archive ? version.archive.name : 'N/A'))]),_c('td',[_vm._v(_vm._s(version.pressRelease ? version.pressRelease.name : 'N/A'))]),_c('td',[_c('router-link',{staticClass:"e-link pageVersions__entryListLink",attrs:{"to":("/admin/versions/edit/" + (version.id) + "#settings")}},[_vm._v("Modifier")]),_c('router-link',{staticClass:"e-link pageVersions__entryListLink",attrs:{"to":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.markVersionForDeletion(version)}}},[_vm._v("Supprimer")])],1)])}),0):_c('p',[_vm._v("Vous n'avez pas encore enregistré de version.")]),(_vm.versionMarkedForDeletion)?_c('b-popin',{on:{"cancel":_vm.cancelVersionDeletion}},[_c('prompt-version-deletion',{attrs:{"version-name":_vm.versionMarkedForDeletion.name},on:{"confirm":_vm.deleteVersion,"cancel":_vm.cancelVersionDeletion}})],1):_vm._e(),_c('e-link',{staticClass:"pageVersions__button",attrs:{"button":true,"to":"/admin/versions/edit#settings"}},[_vm._v("Ajouter une "+_vm._s(_vm.versions.length === 0 ? 'première' : '')+" version")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }