<template>
  <div class="mediaPatch">
    <h3 class="e-h1">Modifier un media: {{ name }} ({{ size }})</h3>
    <b-entity-list class="mediaPatch__table">
      <tr class="mediaPatchLine">
        <td>
          <p>Original</p>
          <div class="mediaPatchLine__preview">
            <video
              v-if="isVideo"
              :src="`${media.originFile.url}#t=0.5`"
              preload="metadata"
            ></video>
            <img v-else :src="media.originFile.url" :alt="name" />
          </div>
          <e-select
            label="Type"
            name="type"
            required
            disabled
            :value="type"
            v-model="type"
          >
            <option value="image" :selected="type === 'image'" v-if="!isVideo"
              >Image</option
            >
            <option value="video" :selected="type === 'video'" v-if="isVideo"
              >Video</option
            >
            <option
              value="youtube"
              :selected="type === 'youtube'"
              v-if="isVideo"
              >Youtube</option
            >
          </e-select>
        </td>
        <td>
          <p>Grille</p>
          <div v-if="media.gridFile" class="mediaPatchLine__preview">
            <video
              v-if="isVideo"
              :src="`${media.gridFile.url}#t=0.5`"
              preload="metadata"
            ></video>
            <img v-else :src="media.gridFile.url" :alt="media.gridFile.name" />
          </div>
          <e-select label="groupe" name="group" required v-model="groups">
            <option
              :selected="this.media.groups.length === 0"
              value=""
            ></option>
            <option
              v-for="(group, i) in savedGroups"
              :key="i"
              :value="group.id"
              :selected="selectedGroup(group.id)"
              >{{ group.name }}</option
            >
          </e-select>
        </td>
        <td>
          <p>Pop-In</p>
          <div v-if="media.popinFile" class="mediaPatchLine__preview">
            <video
              v-if="isVideo"
              :src="`${media.popinFile.url}#t=0.5`"
              preload="metadata"
            ></video>
            <img
              v-else
              :src="media.popinFile.url"
              :alt="media.popinFile.name"
            />
          </div>
          <e-input
            :name="'credits_' + name"
            label="Crédits"
            :value="credit"
            v-model="credit"
          />
        </td>
        <td>
          <p>Description</p>
          <textarea class="mediaPatchLine__description" v-model="description" />
          <e-input
            :name="'yt_' + name"
            type="url"
            required
            label="URL Youtube"
            :value="url"
            v-model="url"
            :class="{
              'mediaPatchLine__youtube--active': type === 'youtube'
            }"
            class="mediaPatchLine__youtube"
          />
          <!-- <e-input
            :name="'credits_' + name"
            label="Crédits"
            :value="credit"
            v-model="credit"
          />
          <e-select label="groupe" name="group" required v-model="groups">
            <option
              :selected="this.media.groups.length === 0"
              value=""
            ></option>
            <option
              v-for="(group, i) in savedGroups"
              :key="i"
              :value="group.id"
              :selected="selectedGroup(group.id)"
              >{{ group.name }}</option
            >
          </e-select> -->
          <!-- <e-select
            label="Type"
            name="type"
            required
            disabled
            :value="type"
            v-model="type"
          >
            <option value="image" :selected="type === 'image'" v-if="!isVideo"
              >Image</option
            >
            <option value="video" :selected="type === 'video'" v-if="isVideo"
              >Video</option
            >
            <option
              value="youtube"
              :selected="type === 'youtube'"
              v-if="isVideo"
              >Youtube</option
            >
          </e-select> -->
        </td>
      </tr>
    </b-entity-list>

    <footer class="mediaPatch__footer">
      <e-button @click.native="save">Enregistrer</e-button>
      <e-button @click.native="$emit('cancel')">Annuler</e-button>
    </footer>
  </div>
</template>

<script>
import { /* mapMutations, */ mapState } from 'vuex'
import formatBytes from '../../scripts/format-bytes'

export default {
  props: {
    media: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: this.media.originFile.name,
      type: this.media.type,
      groups: null,
      description: this.media.content,
      url: this.media.type === 'youtube' ? this.media.url : '',
      credit:
        this.media.name === 'null' || !this.media.name ? '' : this.media.name
    }
  },
  computed: {
    ...mapState({
      savedGroups: state => state.groups.list
    }),
    size() {
      return formatBytes(this.media.originFile.size)
    },
    isVideo() {
      return this.type === 'video' || this.type === 'youtube'
    }
  },
  mounted() {
    //   this.formData.set('file', this.file)
    console.log(this.groups)
    if (this.media.groups.length > 0) {
      this.groups = `${this.media.groups[0].id}`
    }
  },
  //   this.formData.set('name', this.credit)
  //   this.formData.set('groups', this.groups)
  //   this.formData.set('type', this.type)

  //   if (this.type === 'youtube') {
  //     this.formData.set('url', this.url)
  //   }
  // },
  methods: {
    selectedGroup(id) {
      if (this.media.groups.length === 0) return false

      if (this.media.groups.find(group => group.id === id)) {
        return true
      }
    },
    // ...mapMutations({
    //   removePendingUploadFileList: 'medias/removePendingUploadFileList',
    //   incrementUploadCount: 'medias/incrementUploadCount'
    // }),
    // remove() {
    //   this.removePendingUploadFileList(this.file)
    // },
    async save() {
      const update = { name: this.credit }

      update.groups = this.groups ? [{ id: parseInt(this.groups, 10) }] : []

      if (this.url) update.url = this.url

      update.content = this.description

      this.$emit('confirm', update)
      // console.log(this.type, this.groups, this.name)

      // const update = {}

      // if (this.credit.length > 0) {
      //   update.name = this.credit
      // }

      // if (this.groups && typeof this.groups === 'string') {
      //   update.groups = parseInt(this.groups)
      // }

      // if (this.url && this.url.length > 0) {
      //   update.url = this.url
      // }

      // const { data } = await axiosClient.patch(
      //   `/medias/${this.media.id}`,
      //   update
      // )

      // console.log(data)
      // if (this.uploaded) return
      // this.uploading = true
      // return axiosClient
      //   .post('/medias/upload', this.formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data'
      //     }
      //   })
      //   .then(() => {
      //     this.uploading = false
      //     this.uploaded = true
      //     this.incrementUploadCount()
      //     this.$store.dispatch('notifications/present', {
      //       type: 'Notice',
      //       text: `Le fichier ${this.name} a été correctement téléchargé.`
      //     })
      //   })
      //   .catch(() => {
      //     this.uploading = false
      //     this.uploaded = false
      //     this.$store.dispatch('notifications/present', {
      //       type: 'Erreur',
      //       text: `Une erreur s'est produite lors du téléchargement du fichier ${this.name}. Veuillez réessayer ulterieurement.`
      //     })
      //   })
    }
  }
}
</script>

<style lang="scss">
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
.mediaPatch {
  width: calc(100% - 40px);
  max-width: 1280px;
  overflow: auto;
  padding: var(--spacing-xl);
  background-color: var(--white);

  > * {
    width: 100%;
  }

  &__footer {
    margin-top: var(--spacing-xl);
  }
}

.mediaPatchLine {
  width: 100%;

  &--uploading {
    td:last-child::before {
      content: '';
      box-sizing: border-box;
      display: block;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      width: 20px;
      height: 20px;
      // margin-top: -10px;
      // margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #000;
      animation: spinner 0.6s linear infinite;
      margin: auto;
    }
  }

  &:first-child {
    border-top: 1px solid var(--black);
  }

  p {
    margin-top: 0;
  }

  &__preview {
    width: 100%;
    height: auto;
    min-height: 140px;
    max-height: 160px;
    margin-bottom: var(--spacing-l);
    overflow: hidden;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  td {
    vertical-align: top;
    width: 180px;

    &:not(:first-child) {
      padding-left: var(--spacing-m);
    }
    &:not(:last-child) {
      padding-right: var(--spacing-m);
    }
    &:last-child {
      text-align: left;

      // > * + * {
      //   margin-top: 10px;
      // }

      p {
        margin: 0;
      }
    }
  }

  &__description {
    font-family: 'HelveticaNeue', sans-serif;
    font-size: 10px;
    border: 1px solid black;
    border-radius: 0;
    min-height: 160px;
    width: 100%;
    margin-bottom: var(--spacing-l);
    margin-top: 10px;
  }

  &__youtube {
    $yt: &;
    // margin-top: -2px;

    &:not(#{$yt}--active) {
      pointer-events: none;
      opacity: 0.25;
    }
  }
}
</style>
