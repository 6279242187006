<template>
  <b-page class="mediaPage">
    <h1 class="e-h1">medias</h1>
    <e-link
      class="mediaPage__button mediaPage__button--top"
      :button="true"
      to="/admin/medias/upload"
      >Ajouter des medias</e-link
    >
    <media-list
      class="mediaPage__mediaList"
      @editMedia="onEditMedia"
      @deleteMedia="onDeleteMedia"
      @deleteMedias="onDeleteMedias"
      use-grid-file
      bulk-selectable
    ></media-list>
    <e-link class="mediaPage__button" button to="/admin/medias/upload"
      >Ajouter des medias</e-link
    >
    <b-popin
      v-if="
        mediaMarkedForDeletion ||
          mediaMarkedForEdition ||
          mediasMarkedForDeletion
      "
      @cancel="cancelMarkedMedia"
    >
      <prompt-media-deletion
        v-if="mediaMarkedForDeletion"
        :media-name="mediaMarkedForDeletion.originFile.name"
        @confirm="deleteMedia"
        @cancel="cancelMarkedMedia"
      />
      <prompt-media-bulk-deletion
        v-if="mediasMarkedForDeletion || true"
        :mediasIds="mediasMarkedForDeletion"
        @confirm="deleteMedias"
        @cancel="cancelMarkedMedias"
      />
      <media-patch
        v-if="mediaMarkedForEdition"
        :media="mediaMarkedForEdition"
        @confirm="editMedia"
        @cancel="cancelMarkedMedia"
      />
    </b-popin>
  </b-page>
</template>

<script>
import { mapGetters } from 'vuex'
// import { mapState } from 'vuex'
import MediaList from '../../components/block/media-list'
import PromptMediaDeletion from '../../components/elements/prompt-media-deletion'
import PromptMediaBulkDeletion from '../../components/elements/prompt-media-bulk-deletion'
import MediaPatch from '../../components/block/media-patch'

import store from '../../store'

export default {
  components: {
    MediaList,
    PromptMediaDeletion,
    PromptMediaBulkDeletion,
    MediaPatch
  },
  data() {
    return {
      mediaMarkedForDeletion: null,
      mediasMarkedForDeletion: null,
      mediaMarkedForEdition: null
    }
  },
  computed: {
    ...mapGetters({
      findMediaById: 'medias/findById'
    })
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('groups/fetch')
    next()
  },
  // computed: {
  //   ...mapState({
  //     mediasList: state => state.medias.list
  //   })
  // },
  methods: {
    onEditMedia(id) {
      this.mediaMarkedForEdition = this.findMediaById(id)
    },
    editMedia(update) {
      if (!this.mediaMarkedForEdition) return
      try {
        const { id, originFile } = this.mediaMarkedForEdition
        this.$store.dispatch('medias/patch', { id, update }).then(() => {
          this.$store.dispatch('notifications/present', {
            type: 'Notice',
            text: `Le media ${originFile.name} a été mis à jour.`
          })
          this.mediaMarkedForEdition = null
        })
      } catch (err) {
        console.log(err)
        this.$store.dispatch('notifications/present', {
          type: 'Erreur',
          text: `Le media ${origin.name} n'a pas pu être mis à jour.`
        })
      }
    },
    onDeleteMedia(id) {
      this.mediaMarkedForDeletion = this.findMediaById(id)
    },

    onDeleteMedias(ids) {
      console.log('-----', ids)
      this.mediasMarkedForDeletion = ids
    },

    async deleteMedia() {
      if (!this.mediaMarkedForDeletion) return
      const { id, originFile } = this.mediaMarkedForDeletion
      try {
        await this.$store.dispatch('medias/delete', id)
        this.$store.dispatch('notifications/present', {
          type: 'Notice',
          text: `Le media ${originFile.name} a  été supprimé`
        })
        this.mediaMarkedForDeletion = null
      } catch (err) {
        this.$store.dispatch('notifications/present', {
          type: 'Erreur',
          text: `Le media ${originFile.name} n'a pas pu être supprimé. Il est sûrement utiliser dans une grille de version. Essayez de le retirer ou bien réessayez plus tard.`
        })
        this.cancelMarkedMedia()
      }
    },

    async deleteMedias() {
      if (!this.mediasMarkedForDeletion) return
      try {
        await Promise.all(
          this.mediasMarkedForDeletion.map(async id => {
            await this.$store.dispatch('medias/delete', id)
          })
        )

        this.$store.dispatch('notifications/present', {
          type: 'Notice',
          text: `Les ${this.mediasMarkedForDeletion.length} medias ont été supprimé`
        })
        this.mediasMarkedForDeletion = null
      } catch (err) {
        this.$store.dispatch('notifications/present', {
          type: 'Erreur',
          text: `Des medias n'ont pas pu être supprimé. Il sont sûrement utiliser dans une grille de version. Essayez de les retirer ou bien réessayez plus tard.`
        })
        this.cancelMarkedMedias()
      }
    },

    cancelMarkedMedias() {
      this.mediasMarkedForDeletion = null
    },

    cancelMarkedMedia() {
      this.mediaMarkedForDeletion = null
      this.mediaMarkedForEdition = null
    }
  }
}
</script>

<style lang="scss">
.mediaPage {
  h1 {
    margin-bottom: 25px;
  }

  &__mediaList {
    margin-bottom: var(--spacing-xl);
  }

  &__button {
    &--top {
      margin-bottom: var(--spacing-xl);
    }
  }
}
</style>
