<template>
  <div class="promptMediaSelection">
    <h3 class="e-h1">Sélectionner un media</h3>
    <media-list
      selectable
      class="promptMediaSelection__mediaList"
      use-grid-file
    />
    <footer class="promptMediaSelection__footer">
      <e-button
        :disabled="!pendingGridMedia"
        @click.native.prevent="onConfirmSelection"
        >{{ confirmText }}</e-button
      >
      <e-button @click.native.prevent="$emit('cancel')">Annuler</e-button>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MediaList from './media-list'

export default {
  components: {
    MediaList
  },
  data() {
    return {
      selectedMedia: false,
      confirmText: 'Selectionner'
    }
  },
  computed: {
    ...mapState({
      pendingGridMedia: state => state.medias.pendingGridMedia
    })
  },
  mounted() {
    if (this.pendingGridMedia) this.confirmText = 'Remplacer'
  },
  methods: {
    onConfirmSelection() {
      this.$emit('confirm')
    },
    onCancelSelection() {
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss">
.promptMediaSelection {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: calc(100% - 2 * var(--spacing-xl));
  max-height: 600px;
  max-width: 1024px;
  overflow: auto;
  padding: var(--spacing-xl);
  background-color: var(--white);

  h3 {
    margin-bottom: 25px;
  }

  &__mediaList {
    margin-bottom: var(--spacing-xl);
  }

  &__footer {
    text-align: center;
  }
}
</style>
