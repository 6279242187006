<template>
  <b-page max-width class="groupEdit">
    <e-edit-header back-to="/admin/groups">{{ pageTitle }}</e-edit-header>
    <form ref="form" @submit.prevent="saveGroup" class="groupEdit__form">
      <e-input
        class="groupEdit__input"
        label="nom"
        placeholder="nom"
        name="name"
        required
        @input="saved = false"
        v-model="currentGroup.name"
      />
      <e-input
        class="groupEdit__input"
        label="Description"
        placeholder="Lorem Ipsum"
        name="description"
        @input="saved = false"
        v-model="currentGroup.description"
      />

      <e-input
        class="groupEdit__input"
        label="Rank"
        name="rank"
        @input="saved = false"
        v-model="currentGroup.rank"
      />

      <e-upload-button
        class="groupEdit__upload"
        title="Archive"
        types=".zip"
        @change="saved = false"
      />

      <div class="groupEdit__popin">
        <h2 class="e-h1">Paramètres grille d'affichage d'un groupe (pop-in)</h2>

        <e-select
          class="groupEdit__input"
          label="Nombre de colonnes"
          name="popinColumnsCount"
          required
          v-model="currentGroup.popinColumnsCount"
          @input="saved = false"
        >
          <option value="1" :selected="currentGroup.popinColumnsCount === 1"
            >1</option
          >
          <option value="3" :selected="currentGroup.popinColumnsCount === 3"
            >3</option
          >
          <option value="6" :selected="currentGroup.popinColumnsCount === 6"
            >6</option
          >
        </e-select>

        <e-select
          class="groupEdit__input"
          label="Comportement au clique sur l'image ou la video d'un groupe (pop-in)"
          name="clickItemBehaviour"
          required
          :value="currentGroup.clickItemBehaviour"
          v-model="currentGroup.clickItemBehaviour"
          @input="saved = false"
        >
          <option
            value="default"
            :selected="currentGroup.clickItemBehaviour === 'default'"
            >Téléchargement si autorisé, ouverture du media (pop-in) le cas
            échéant</option
          >
          <option
            value="inactive"
            :selected="currentGroup.clickItemBehaviour === 'inactive'"
            >Il ne se passe rien</option
          >
        </e-select>
      </div>

      <e-button :disabled="saved" type="submit">Enregistrer</e-button>
      <e-link button to="/admin/groups">Annuler</e-link>
    </form>
    <b-popin v-if="showCancelPrompt" @cancel="hideCancelPrompt">
      <prompt-group-cancel @confirm="leaveRoute" @cancel="hideCancelPrompt" />
    </b-popin>
  </b-page>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/admin/store'
import PromptGroupCancel from '../../components/elements/prompt-group-cancel'

export default {
  components: {
    PromptGroupCancel
  },
  data() {
    return {
      saved: false,
      showCancelPrompt: false,
      leaveRoute: null
    }
  },
  computed: {
    ...mapState({
      currentGroup: state => state.group
    }),
    pageTitle() {
      return !this.$route.params.id ? 'Créer un groupe' : 'Modifier un groupe'
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.id) {
      await store.dispatch('group/fetch', to.params.id)
      next(vm => {
        vm.saved =
          from.name === 'adminGroupCreate' ||
          (from.name !== 'adminVersionsEdit' &&
            from.name !== 'adminGroupCreate')
      })
    } else {
      store.dispatch('group/reset')
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.showCancelPrompt = !this.saved
    if (this.showCancelPrompt) this.leaveRoute = next
    else next()
  },
  methods: {
    hideCancelPrompt() {
      this.showCancelPrompt = false
    },
    async saveGroup() {
      document.body.classList.add('loading')
      this.$store.commit('ui/startPending')
      const action = this.$route.params.id ? 'updateGroup' : 'createGroup'
      const formData = new FormData(this.$refs.form)

      try {
        const { data } = await this.$store.dispatch(`group/${action}`, formData)
        this.$store.commit('ui/stopPending')
        this.saved = true

        this.$store.dispatch('notifications/present', {
          type: 'Notice',
          text: `Le groupe ${this.currentGroup.name} a été ${
            this.$route.params.id ? 'modifié' : 'créé'
          }.`
        })

        if (!this.$route.params.id) {
          this.$router.push(`/admin/groups/edit/${data.id}`)
        }
      } catch (err) {
        this.$store.dispatch('notifications/present', {
          type: 'Erreur',
          text: `Une erreur s'est produite: ${err}.`
        })
      }
    }
  }
}
</script>

<style lang="scss">
.groupEdit {
  &__input {
    margin-bottom: 15px;
  }
  &__upload {
    margin-bottom: 32px;
  }

  &__popin {
    h2 {
      font-weight: bold im !important;
    }
    margin-bottom: 32px;
  }
}
</style>
