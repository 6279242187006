<template>
  <footer
    :class="{
      'pageFooter--autoMargin': autoMargin && !noMargin,
      'pageFooter--noMargin': noMargin
    }"
    class="pageFooter"
  >
    <a :href="legalNotice" target="_blank" rel="noopener noreferrer"
      >Legal Notice</a
    >
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PageFooter',
  props: {
    noMargin: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      top: 0,
      autoMargin: false
    }
  },
  computed: {
    ...mapState({
      legalNotice: state => state.configuration.legalNotice
    })
  }
  // mounted() {
  //   window.addEventListener('resize', this.onResize, false)
  //   this.onResize()

  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.onResize, false)
  // },
  // methods: {
  //   onResize() {
  //     const rect = this.$el.getBoundingClientRect()
  //     this.autoMargin = rect.top < window.innerHeight - rect.height
  //   }
  // }
}
</script>

<style lang="scss">
.pageFooter {
  background-color: var(--white);
  color: var(--black);
  padding: 17px;
  margin-top: 4px;

  .dark & {
    background-color: var(--black);
    color: var(--white);
  }

  @media screen and (min-width: 768px) {
    padding: 17px 30px;
    margin-top: 0;
  }

  &--autoMargin {
    margin-top: auto !important;
  }

  &--noMargin {
    margin-top: 0 !important;
  }

  a {
    display: block;
    font-size: 10px;
    line-height: 12px;
    text-decoration: unset;
    text-transform: uppercase;
  }
}
</style>
