<template>
  <tr
    :class="{ 'mediaEditorLine--uploading': uploading }"
    class="mediaEditorLine"
  >
    <td class="mediaEditorLine__check">
      <e-checkbox
        ref="select"
        type="checkbox"
        name="group"
        v-model="selected"
      />
    </td>
    <!-- MEDIA 1 -->
    <td>
      <p class="mediaEditorLine__position">Original:</p>
      <div class="mediaEditorLine__preview">
        <video
          ref="video"
          v-if="isVideo"
          :src="source"
          preload="metadata"
        ></video>
        <img v-else :src="source" :alt="name" />
      </div>
      <e-select
        label="Type"
        name="type"
        required
        :disabled="!isVideo || uploading || uploaded"
        :value="type"
        v-model="type"
      >
        <option value="image" v-if="!isVideo">Image</option>
        <option value="video" v-if="isVideo">Video</option>
        <option value="youtube" v-if="isVideo">Youtube</option>
      </e-select>
    </td>
    <!-- MEDIA 2 -->
    <td>
      <p class="mediaEditorLine__position">Grille</p>
      <div v-if="gridFile" class="mediaEditorLine__preview">
        <video
          ref="video"
          v-if="getType(gridFile[0]) === 'video'"
          :src="getSource(gridFile[0])"
          preload="metadata"
        ></video>
        <img v-else :src="getSource(gridFile[0])" :alt="gridFile[0].name" />
      </div>
      <e-upload-button
        :has-text="false"
        v-else
        name="medias"
        types=".png,.jpg,.webp,.mp4,.webm"
        v-model="gridFile"
        class="uploadMediaPage__uploadButton mediaEditorLine__uploadButton"
      />
      <e-select
        label="groupe"
        name="group"
        :disabled="uploading || uploaded"
        :value="groups"
        v-model="groups"
      >
        <option :selected="!groups" value=""></option>
        <option
          v-for="(group, i) in savedGroups"
          :key="i"
          :value="group.id"
          :selected="groups === group.id + ''"
          >{{ group.name }}</option
        >
      </e-select>
    </td>
    <!-- MEDIA 3 -->
    <td>
      <p class="mediaEditorLine__position">Pop-In</p>
      <div v-if="popinFile" class="mediaEditorLine__preview">
        <video
          ref="video"
          v-if="getType(popinFile[0]) === 'video'"
          :src="getSource(popinFile[0])"
          preload="metadata"
        ></video>
        <img v-else :src="getSource(popinFile[0])" :alt="popinFile[0].name" />
      </div>
      <e-upload-button
        v-else
        :has-text="false"
        name="medias"
        types=".png,.jpg,.webp,.mp4,.webm"
        v-model="popinFile"
        class="uploadMediaPage__uploadButton mediaEditorLine__uploadButton"
      />
      <e-input
        :name="'credits_' + name"
        label="Crédits"
        :value="credit"
        :disabled="uploading || uploaded"
        v-model="credit"
      />
    </td>
    <td class="mediaEditorLine__options">
      <p class="mediaEditorLine__position">Déscription</p>
      <textarea v-model="description" class="mediaEditorLine__description" />
      <e-input
        :name="'yt_' + name"
        type="url"
        :required="type === 'youtube'"
        label="URL Youtube"
        :value="url"
        :disabled="uploading || uploaded"
        v-model="url"
        :class="{
          'mediaEditorLine__youtube--active': type === 'youtube'
        }"
        class="mediaEditorLine__youtube"
      />
      <e-select
        v-if="type === 'youtube'"
        label="Taille dans la grille"
        style="margin-top: 25px;"
        :name="'gridSize_' + name"
        :disabled="uploading || uploaded"
        :value="3"
        v-model="gridSize"
      >
        <option selected value="3">3</option>
        <option value="1">1</option>
      </e-select>
    </td>
    <td
      :style="`--uploading-progress: ${uploadingProgress}`"
      class="mediaEditorLine__actions"
    >
      <p v-if="!uploading && uploaded">Téléchargé</p>
      <e-button v-else-if="!uploading" @click.native="remove"
        >Supprimer</e-button
      >
    </td>
  </tr>
</template>

<script>
import axiosClient from '../../scripts/axios-admin'
import { mapMutations, mapState } from 'vuex'
import formatBytes from '../../scripts/format-bytes'

export default {
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      fileReader: new FileReader(),
      formData: new FormData(),
      name: this.files[0].name,
      type: this.files[0].type.split('/')[0],
      groups: null,
      url: null,
      gridSize: 3,
      credit: null,
      uploading: false,
      uploadingProgress: 0,
      uploaded: false,
      selected: false,
      gridFile: null,
      popinFile: null,
      description: ''
    }
  },
  computed: {
    ...mapState({
      savedGroups: state => state.groups.list
    }),
    file() {
      return this.files[0]
    },
    source() {
      return URL.createObjectURL(this.file)
    },
    size() {
      return formatBytes(this.file.size)
    },
    isVideo() {
      return this.type === 'video' || this.type === 'youtube'
    }
  },
  mounted() {
    this.formData.set('originFile', this.file)
    if (this.isVideo) {
      this.$refs.video.addEventListener(
        'progress',
        this.setVideoCurrentTime,
        false
      )
    }
  },
  updated() {
    if (
      this.gridFile &&
      (!this.formData.has('gridFile') ||
        this.formData.get('gridFile') !== this.gridFile)
    )
      this.formData.set('gridFile', this.gridFile[0])
    if (
      this.popinFile &&
      (!this.formData.has('popinFile') ||
        this.formData.get('popinFile') !== this.popinFile)
    )
      this.formData.set('popinFile', this.popinFile[0])

    this.formData.set('name', this.credit)
    this.formData.set('groups', this.groups || [])
    this.formData.set('type', this.type)
    this.formData.set('content', this.description)
    this.formData.set('width', Number(this.gridSize) || 1)

    console.log('updated here', this.gridSize, Number(this.gridSize) || 1)

    if (this.type === 'youtube') {
      this.formData.set('url', this.url)
    }

    if (this.selected) {
      this.incrementUploadBatchCount()
    } else {
      this.decrementUploadBatchCount()
    }
  },
  beforeDestroy() {
    if (this.isVideo) {
      this.$refs.video.removeEventListener(
        'progress',
        this.setVideoCurrentTime,
        false
      )
    }
  },
  methods: {
    ...mapMutations({
      removePendingUploadFileList: 'medias/removePendingUploadFileList',
      incrementUploadCount: 'medias/incrementUploadCount',
      incrementUploadBatchCount: 'medias/incrementUploadBatchCount',
      decrementUploadBatchCount: 'medias/decrementUploadBatchCount'
    }),
    setVideoCurrentTime(e) {
      e.target.currentTime = 0.5
    },
    remove() {
      this.removePendingUploadFileList([
        this.files[0],
        this.gridFile ? this.gridFile[0] : null,
        this.popinFile ? this.popinFile[0] : null
      ])
      this.gridFile = null
      this.popinFile = null
    },
    updateProgressBarValue(progress) {
      this.uploadingProgress = progress
    },
    getSource(file) {
      return URL.createObjectURL(file)
    },
    getType(file) {
      return file.type.split('/')[0]
    },
    upload() {
      if (this.uploaded) return
      this.uploading = true

      console.log('uploaded', this.formData)

      return axiosClient
        .post('/medias/upload', this.formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader('content-length') ||
                progressEvent.target.getResponseHeader(
                  'x-decompressed-content-length'
                )

            if (totalLength === null) return

            const progress = progressEvent.loaded / totalLength
            this.updateProgressBarValue(progress)
          }
        })
        .then(() => {
          this.uploading = false
          this.uploaded = true
          this.uploadingProgress = 0

          this.incrementUploadCount()

          this.$store.dispatch('notifications/present', {
            type: 'Notice',
            text: `Le fichier ${this.name} a été correctement téléchargé.`
          })
        })
        .catch(() => {
          this.uploading = false
          this.uploaded = false

          this.$store.dispatch('notifications/present', {
            type: 'Erreur',
            text: `Une erreur s'est produite lors du téléchargement du fichier ${this.name}. Veuillez réessayer ulterieurement.`
          })
        })
    }
  }
}
</script>

<style lang="scss">
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.mediaEditorLine {
  width: 100%;

  &__check {
    width: 30px;
  }

  &__position {
    font-size: 8px;
  }

  td:nth-child(2),
  td:nth-child(3),
  td:nth-child(4),
  &__actions {
    width: 180px;
  }

  &--uploading {
    td:last-child::before {
      content: '';
      box-sizing: border-box;
      display: block;
      width: calc(100% - var(--spacing-m));
      height: 5px;
      border: 1px solid var(--black);
      border-radius: 10px;
      background-image: linear-gradient(
        to right,
        var(--black),
        var(--black) calc(100% * var(--uploading-progress)),
        var(--white) calc(100% * var(--uploading-progress)),
        var(--white)
      );
    }
  }

  &:first-child {
    border-top: 1px solid var(--black);
  }

  p {
    margin-top: 0;
  }

  &__preview {
    width: 100%;
    height: 104px;

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  td {
    vertical-align: top;

    &:first-child,
    &:last-child {
      vertical-align: middle;
    }

    &:not(:first-child) {
      padding-left: var(--spacing-m);
    }
    &:not(:last-child) {
      padding-right: var(--spacing-m);
    }
    &:last-child {
      text-align: right;

      p {
        margin: 0;
      }
    }
  }

  &__uploadButton,
  &__preview,
  &__description {
    margin-bottom: var(--spacing-xl) !important;
  }

  &__uploadButton,
  &__description {
    min-height: 104px;
  }

  &__description {
    width: 100%;
    border: 1px solid black;
    border-radius: 0;
    font-size: 10px;
    font-family: sans-serif;
  }

  &__youtube {
    $yt: &;
    margin-top: -2px;

    &:not(#{$yt}--active) {
      pointer-events: none;
      opacity: 0.25;
    }
  }
}
</style>
