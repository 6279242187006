<template>
  <div
    :class="{
      'mediaList--selectable': selectable,
      'mediaList--loading': !loaded
    }"
    class="mediaList"
  >
    <!-- <div v-if="!loaded" class="mediaList__loader">
      Recupération des médias.
    </div>
    <template v-else> -->
    <div class="mediaList__filters">
      <e-select
        label="Filtrer par type"
        name="type"
        :value="selectedType"
        v-model="selectedType"
        class="mediaList__select"
      >
        <option value="all">Tous</option>
        <option v-for="(type, i) in availableTypes" :key="i" :value="type">{{
          type
        }}</option>
      </e-select>
      <e-select
        label="Filtrer par groupe"
        name="group"
        :value="selectedGroup"
        v-model="selectedGroup"
        class="mediaList__select"
      >
        <option value="all">Tous</option>
        <option
          v-for="(group, i) in availableGroups"
          :key="i"
          :value="group.id"
          >{{ group.name }}</option
        >
      </e-select>
      <e-button
        @click.native="deleteItems"
        style="margin-left: 25px; margin-top: 0;"
        v-if="this.bulkSelectors.length"
      >
        Supprimer la sélection
      </e-button>
    </div>
    <div class="mediaList__content">
      <component
        :is="itemTag"
        v-for="(item, i) in filteredMedias"
        :key="i"
        :class="{
          'mediaList__item--selectable': selectable,
          'mediaList__item--selected': selected === item.id
        }"
        class="mediaList__item"
      >
        <div v-if="!selectable" class="mediaList__overlay">
          <button @click="$emit('editMedia', item.id)"><edit /></button>
          <button @click="$emit('deleteMedia', item.id)"><trash /></button>
        </div>
        <media-thumbnail
          class="mediaList__thumbnail"
          :media="item"
          :use-grid-file="useGridFile"
        />
        <input
          v-if="selectable"
          type="radio"
          :value="item.id"
          :id="item"
          :name="item"
          v-model="selected"
          class="mediaList__selector"
        />
        <input
          type="checkbox"
          name="bulkSelect"
          class="mediaList__itemBulkSelect"
          :value="item.id"
          v-model="bulkSelectors"
          v-if="bulkSelectable"
        />
      </component>
    </div>
    <div v-if="!loaded" class="mediaList__loader">
      Recupération des derniers médias.
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import MediaThumbnail from '../elements/media-thumbnail'
import Edit from '../svg/edit.svg'
import Trash from '../svg/trash.svg'

export default {
  components: {
    MediaThumbnail,
    Edit,
    Trash
  },
  props: {
    selectable: {
      type: Boolean,
      required: false,
      default: false
    },
    useGridFile: {
      type: Boolean,
      required: false,
      default: false
    },
    bulkSelectable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      bulkSelectors: [],
      selectedType: 'all',
      selectedGroup: 'all',
      selected: this.$store.state.medias.pendingGridMedia,
      loaded: false
    }
  },
  computed: {
    ...mapState({
      mediasList: state => state.medias.list
    }),
    ...mapGetters({
      availableTypes: 'medias/availableTypes',
      availableGroups: 'medias/availableGroups'
    }),
    itemTag() {
      return this.selectable ? 'label' : 'div'
    },
    filteredMedias() {
      const resultsByType =
        this.selectedType === 'all'
          ? this.mediasList
          : this.mediasList.filter(media => media.type === this.selectedType)
      const resultsByGroup =
        this.selectedGroup === 'all'
          ? resultsByType
          : resultsByType.filter(media =>
              media.groups.some(
                ({ id }) => id === parseInt(this.selectedGroup, 10)
              )
            )
      return resultsByGroup
    }
  },
  async created() {
    await this.$store.dispatch('medias/fetch')
    this.loaded = true
  },
  updated() {
    if (this.selectable && this.selected)
      this.setPendingGridMedia(this.selected)
  },
  methods: {
    ...mapMutations({
      setPendingGridMedia: 'medias/setPendingGridMedia'
    }),
    deleteItems() {
      this.$emit('deleteMedias', this.bulkSelectors)
      this.bulkSelectors = []
    }
  }
}
</script>

<style lang="scss">
.mediaList {
  &:not(&--loading) {
    overflow-y: auto;
  }

  &__loader {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-xl);

    &::before {
      content: '';
      box-sizing: border-box;
      display: block;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      width: 20px;
      height: 20px;
      // margin-top: -10px;
      // margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #000;
      animation: spinner 0.6s linear infinite;
      margin-right: var(--spacing-m);
    }
  }

  &--selectable {
    padding: 2px;
  }

  &__filters {
    display: flex;
    margin-bottom: var(--spacing-l);
  }

  &__select {
    width: 20%;
    min-width: 200px;
  }

  &__select + &__select {
    margin-left: var(--spacing-xl);
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 10px;
    row-gap: 10px;
  }

  &__item {
    position: relative;
    border: 1px solid var(--black);

    &--selectable {
      cursor: pointer;
    }

    &--selected {
      outline: 3px solid #2771ff;
    }
  }

  &,
  &__thumbnail {
    position: relative;
  }

  &__thumbnail {
    z-index: 1;
  }

  &__selector {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    visibility: hidden;
  }

  &__overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item:hover &__overlay {
    opacity: 1;

    button {
      border: none;
      background: transparent;
    }
  }

  &__itemBulkSelect {
    z-index: 3;
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 15px;
    height: 15px;
  }
}
</style>
