import axiosAdmin from '../scripts/axios-admin'

export default {
  namespaced: true,
  state: {
    id: -1,
    name: null,
    description: null
  },
  mutations: {
    init(state) {
      state.id = -1
      state.name = null
      state.description = null
      state.popinColumnsCount = null
      state.rank = null
      state.clickItemBehaviour = null
    },
    setCurrentGroup(state, payload) {
      state.id = payload.id
      state.name = payload.name
      state.description = payload.description
      state.popinColumnsCount = payload.popinColumnsCount
      state.rank = payload.rank
      state.clickItemBehaviour = payload.clickItemBehaviour
    }
  },
  actions: {
    reset({ commit }) {
      commit('init')
    },
    async fetch({ commit }, id) {
      const { data } = await axiosAdmin.get(`/groups/${id}`)
      commit('setCurrentGroup', data)
    },
    async updateGroup({ state }, data) {
      return await axiosAdmin.request({
        data,
        method: 'patch',
        url: `/groups/${state.id}`,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    },
    async createGroup(_, data) {
      return await axiosAdmin.request({
        data,
        method: 'post',
        url: `/groups`,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
    }
  }
}
