import fetcher from '../assets/scripts/fetcher'

export default {
  namespaced: true,

  state: {
    id: -1,
    name: '',
    password: '',
    canDownload: false,
    public: false,
    gridDesktopId: -1,
    gridMobileId: -1,
    gridDesktop: {},
    gridMobile: {},
    createdAt: '',
    updatedAt: '',
    groups: [],
    slug: null
  },

  mutations: {
    updateVersion(
      state,
      {
        id,
        name,
        gridDesktop,
        gridMobile,
        createdAt,
        updatedAt,
        groups,
        canDownload,
        pressRelease,
        archive
      }
    ) {
      state.id = id
      state.name = name
      state.gridDesktop = gridDesktop
      state.gridMobile = gridMobile
      state.createdAt = createdAt
      state.updatedAt = updatedAt
      state.groups = groups
      state.canDownload = canDownload
      state.pressRelease = pressRelease
      state.archive = archive
    }
  },

  actions: {
    async fetch({ commit, rootState }, password) {
      const { data } = await fetcher.get('/versions/auth', {
        params: { password }
      })

      data.gridDesktop.items.forEach(item => {
        item.media = rootState.medias.medias.find(m => m.id === item.mediaId)
      })
      data.gridMobile.items.forEach(item => {
        item.media = rootState.medias.medias.find(m => m.id === item.mediaId)
      })

      data.groups = data.groups.sort((a, b) => {
        return a.rank - b.rank
      })

      // console.log(data, rootState.medias.medias)

      commit('updateVersion', data)
      return data
    }
  }
}
