<template>
  <router-link
    class="adminLink"
    :class="{
      'adminLink--active': active,
      'adminLink--arrowbefore': arrow === 'before',
      'adminLink--arrowafter': arrow === 'after',
      'adminLink--button': button
    }"
    :to="to"
    ><slot />
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: false
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    arrow: {
      type: String,
      required: false,
      default: ''
    },
    button: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style lang="scss">
.adminLink {
  text-decoration: none;
  font-size: var(--font-s);

  &.router-link-active,
  &.router-link-exact-active {
    font-weight: bold;
  }

  &:hover {
    font-weight: 800;
    // text-decoration: underline;
  }
  &--active {
    font-weight: 800;
    // text-decoration: underline;
  }

  &--button {
    display: inline-block;
    border: 1px solid var(--black);
    padding: var(--spacing-m) var(--spacing-xl);
    background: transparent;
    line-height: 1em;
    margin-top: 25px;

    &:hover {
      background-color: var(--black);
      color: var(--white);
      font-weight: normal;
      // text-decoration: none;
    }
  }

  &--arrow {
    &after::after,
    &before::before {
      content: '';
      display: inline-block;
      background: url('/img/picto/arrow.svg');
      background-size: contain;
      width: 10px;
      height: 10px;
    }

    &after {
      &::after {
        background-position: bottom;
        margin-left: 5px;
        transform: translateY(1px);
      }
    }

    &before {
      &::before {
        background-position: top;
        transform: translateY(1px) rotate(180deg);
        margin-right: 5px;
      }
    }
  }
}
</style>
