<template>
  <b-page class="pageVersions">
    <h1 class="e-h1">Versions</h1>
    <b-entity-list
      v-if="versions.length > 0"
      style="width: 100%"
      :columns="[
        'Nom',
        'Statut',
        'Publique',
        'Archive',
        'Press Release',
        'Actions'
      ]"
      class="pageVersions__entryList"
    >
      <tr v-for="version in versions" :key="version.id">
        <td>{{ version.name }}</td>
        <td>{{ status(version) }}</td>
        <td>{{ version.public ? 'Oui' : 'Non' }}</td>
        <td>{{ version.archive ? version.archive.name : 'N/A' }}</td>
        <td>{{ version.pressRelease ? version.pressRelease.name : 'N/A' }}</td>
        <td>
          <router-link
            class="e-link pageVersions__entryListLink"
            :to="`/admin/versions/edit/${version.id}#settings`"
            >Modifier</router-link
          >
          <router-link
            class="e-link pageVersions__entryListLink"
            to=""
            @click.native.prevent="markVersionForDeletion(version)"
            >Supprimer</router-link
          >
        </td>
      </tr>
    </b-entity-list>

    <p v-else>Vous n'avez pas encore enregistré de version.</p>
    <b-popin v-if="versionMarkedForDeletion" @cancel="cancelVersionDeletion">
      <prompt-version-deletion
        :version-name="versionMarkedForDeletion.name"
        @confirm="deleteVersion"
        @cancel="cancelVersionDeletion"
      />
    </b-popin>

    <e-link
      class="pageVersions__button"
      :button="true"
      to="/admin/versions/edit#settings"
      >Ajouter une {{ versions.length === 0 ? 'première' : '' }} version</e-link
    >
  </b-page>
</template>

<script>
import { mapState } from 'vuex'
import PromptVersionDeletion from '../../components/elements/prompt-version-deletion'

export default {
  name: 'auth',
  components: {
    PromptVersionDeletion
  },
  data() {
    return {
      versionMarkedForDeletion: null
    }
  },
  computed: {
    ...mapState({
      versions: state => state.versions.list
    })
  },
  beforeCreate() {
    this.$store.dispatch('versions/fetch')
  },
  methods: {
    markVersionForDeletion(version) {
      this.versionMarkedForDeletion = version
    },
    cancelVersionDeletion() {
      this.versionMarkedForDeletion = null
    },
    deleteVersion() {
      this.$store
        .dispatch('versions/deleteItem', this.versionMarkedForDeletion.id)
        .then(() => {
          this.versionMarkedForDeletion = null
          this.$store.dispatch('notifications/present', {
            type: 'Notice',
            text: `La version ${name} a  été supprimé`
          })
        })
    },
    status(version) {
      const now = new Date().getTime()
      const startAt = new Date(version.startAt).getTime()
      const endAt = new Date(version.endAt).getTime()
      if (startAt <= now && now <= endAt) return 'Publiée'
      else return 'Non Publiée'
    }
  }
}
</script>

<style lang="scss">
.pageVersions {
  h1 {
    margin-bottom: 25px;
  }

  &__button {
    display: inline-block;
  }

  &__entryList {
    margin-bottom: var(--spacing-l);
    width: 100%;

    tr td:last-child {
      text-align: right;
    }

    &Link + &Link {
      margin-left: 18px;
    }
  }
}
</style>
