<template>
  <div class="promptBatchEdit">
    <p>Modifier {{ count }} éléments.</p>
    <div class="promptBatchEdit__inputs">
      <e-input
        :name="'credits'"
        label="Crédits"
        :value="credit"
        v-model="credit"
      />
      <e-select
        label="groupe"
        name="group"
        required
        :value="groups"
        v-model="groups"
      >
        <option selected disabled value=""></option>
        <option v-for="(group, i) in savedGroups" :key="i" :value="group.id">{{
          group.name
        }}</option>
      </e-select>
    </div>
    <e-button @click.native.prevent="$emit('confirm', { credit, groups })"
      >Confirmer</e-button
    >
    <e-button @click.native.prevent="$emit('cancel')">Annuler</e-button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    count: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      credit: null,
      groups: null
    }
  },
  computed: {
    ...mapState({
      savedGroups: state => state.groups.list
    })
  }
}
</script>

<style lang="scss">
.promptBatchEdit {
  background-color: var(--white);
  display: inline-block;
  padding: var(--spacing-xl);
  border: 1px solid var(--black);

  p {
    margin-top: 0;
    line-height: 1.5em;
  }

  &__inputs {
    margin-bottom: var(--spacing-xl);

    > * + * {
      margin-top: var(--spacing-m);
    }
  }
}
</style>
