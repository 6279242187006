<template>
  <div id="app" :class="theme">
    <ShellHeader :live="isLiveYoutube" />
    <ShellMenu v-if="hasMenuVisible" />
    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import ShellHeader from '@/components/shell/header'
import ShellMenu from '@/components/shell/menu'
export default {
  components: {
    ShellHeader,
    ShellMenu
  },
  data() {
    return {
      live: true
    }
  },
  computed: {
    ...mapState({
      isLiveYoutube: state =>
        state.configuration.live ? JSON.parse(state.configuration.live) : 0,
      hasMenuVisible: state =>
        state.configuration.menuVisible
          ? JSON.parse(state.configuration.menuVisible)
          : 0,
      theme: state => state.configuration.theme || 'light'
    })
  },
  created() {
    const userRole = localStorage.getItem('userRole')
    if (userRole) {
      this.setUserRole(userRole)
    }
  },
  methods: {
    ...mapMutations({
      setUserRole: 'setUserRole'
    })
  }
}
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}
</style>
