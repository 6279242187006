<template>
  <div class="versionEdit">
    <h2 class="e-h1">Informations</h2>
    <div class="versionEdit__infos">
      <e-checkbox
        type="checkbox"
        label="Version publique"
        v-model="$store.state.version.public"
        name="public"
      />
      <e-input
        class="versionEdit__infosInput"
        label="nom"
        placeholder="nom"
        name="name"
        required
        v-model="$store.state.version.name"
      />
      <e-input
        class="versionEdit__infosInput"
        :label="
          `Mot de passe ${
            $store.state.version.public
              ? '(requis, même si la version est publique)'
              : ''
          }`
        "
        placeholder="mot de passe"
        name="password"
        required
        v-model="$store.state.version.password"
      />
      <e-input
        class="versionEdit__infosInput"
        :label="`Permalien ${slug}`"
        placeholder="xxxx-xxx-2"
        name="password"
        required
        v-model="$store.state.version.slug"
      />
    </div>
    <h2 class="e-h1">Plage de publication</h2>
    <div class="versionEdit__dates">
      <div class="versionEdit__datesStart">
        <p class="e-h1">Début:</p>
        <e-input
          class="versionEdit__datesInput"
          placeholder="JJ/MM/AA"
          type="date"
          name="start_date"
          required
          v-model="startAtDate"
        />
        <e-input
          class="versionEdit__datesInput"
          type="time"
          name="start_time"
          required
          v-model="startAtTime"
        />
      </div>
      <div class="versionEdit__datesEnd">
        <p class="e-h1">Fin:</p>
        <e-input
          class="versionEdit__datesInput"
          placeholder="JJ/MM/AA"
          type="date"
          name="end_date"
          required
          v-model="endAtDate"
        />
        <e-input
          class="versionEdit__datesInput"
          type="time"
          name="end_time"
          required
          v-model="endAtTime"
        />
      </div>
    </div>
    <div class="versionEdit__downloadable">
      <e-checkbox
        type="checkbox"
        label="Contenu Téléchargeable"
        v-model="$store.state.version.canDownload"
        name="group"
      />
    </div>
    <h2 class="e-h1">Groupes</h2>
    <div class="versionEdit__groups">
      <e-checkbox
        v-for="(group, i) in availableGroups"
        :key="i"
        type="checkbox"
        :label="group.name"
        v-model="usedGroups[group.id]"
        name="group"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import slugify from 'slugify'

export default {
  props: {
    availableGroups: {
      type: Array,
      required: true
    },
    currentVersionGroups: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      usedGroups: this.availableGroups.reduce((acc, group) => {
        acc[group.id] = this.currentVersionGroups.some(g => g.id === group.id)
        return acc
      }, {}),
      startAtDate: null,
      startAtTime: null,
      endAtDate: null,
      endAtTime: null
    }
  },
  computed: {
    ...mapGetters({
      startAtToInputs: 'version/startAtToInputs',
      endAtToInputs: 'version/endAtToInputs'
    }),
    startAt() {
      try {
        return new Date(
          `${this.startAtDate} ${this.startAtTime} GMT+00:00`
        ).toISOString()
      } catch (err) {
        return null
      }
    },
    endAt() {
      try {
        return new Date(
          `${this.endAtDate} ${this.endAtTime} GMT+00:00`
        ).toISOString()
      } catch (err) {
        return null
      }
    },
    slug() {
      if (this.$store.state.version.public && this.$store.state.version.slug)
        return `(${window.origin}/${this.$store.state.version.slug})`
      return ''
    }
  },
  mounted() {
    const [startAtDate, startAtTime] = this.startAtToInputs
    const [endAtDate, endAtTime] = this.endAtToInputs
    this.startAtDate = startAtDate
    this.startAtTime = startAtTime
    this.endAtDate = endAtDate
    this.endAtTime = endAtTime
  },
  updated() {
    if (!this.$store.state.version.slug) {
      this.$store.state.version.slug = slugify(this.$store.state.version.name)
    }
    for (const groupId in this.usedGroups) {
      if (!Object.hasOwnProperty.call(this.usedGroups, groupId)) continue
      const target = this.availableGroups.find(
        group => group.id === parseInt(groupId, 10)
      )

      if (
        this.usedGroups[groupId] &&
        this.currentVersionGroups.every(
          group => group.id !== parseInt(groupId, 10)
        )
      ) {
        this.addVersionGroup(target)
      }
      if (
        !this.usedGroups[groupId] &&
        this.currentVersionGroups.some(
          group => group.id === parseInt(groupId, 10)
        )
      ) {
        this.removeVersionGroup(target)
      }
    }

    if (this.startAt && this.endAt) {
      this.setCurrentVersion({
        startAt: this.startAt,
        endAt: this.endAt
      })
    }

    this.$emit('updated')
  },
  methods: {
    ...mapMutations({
      setCurrentVersion: 'version/setCurrentVersion',
      addVersionGroup: 'version/addVersionGroup',
      removeVersionGroup: 'version/removeVersionGroup'
    })
  }
}
</script>

<style lang="scss">
.versionEdit {
  &__infos,
  &__dates,
  &__downloadable,
  &__groups {
    margin-bottom: var(--spacing-xl);
  }

  &__infosInput {
    width: 100%;
    margin-bottom: var(--spacing-l);
  }

  &__dates,
  &__infos {
    display: flex;

    &Input {
      flex-grow: 1;
    }
  }

  &__infos {
    flex-wrap: wrap;
    > :first-child {
      width: 100%;
      flex-shrink: 0;
      margin: 1em 0 var(--spacing-xl) 0;
    }
  }

  // &__infosInput + &__infosInput {
  //   margin-left: 20px;
  // }

  &__datesStart,
  &__datesEnd {
    flex-grow: 1;
  }

  &__datesEnd {
    margin-left: 20px;
  }

  &__inner {
    width: 70vw;
    background: var(--white);
    padding: 40px;
  }

  &__groups {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 20px;
    row-gap: var(--spacing-s);
  }

  &__infosLabel {
    width: 100%;
    display: flex;

    p {
      width: 50%;
      font-size: 8px;

      & + p {
        margin-left: 20px;
      }
    }
  }
}
</style>
