<template>
  <b-page class="versionPage">
    <e-edit-header back-to="/admin/versions">{{ pageTitle }}</e-edit-header>
    <form ref="form" @submit.prevent="saveVersion">
      <header class="versionPage__header">
        <div class="versionPage__tabs">
          <router-link class="versionPage__tab" :to="toTab('settings')"
            >Paramètres généraux</router-link
          >
          <router-link class="versionPage__tab" :to="toTab('desktop-grid')"
            >Grille desktop</router-link
          >
          <router-link class="versionPage__tab" :to="toTab('mobile-grid')"
            >Grille mobile</router-link
          >
        </div>
        <div class="versionPage__actions">
          <e-button
            :disabled="saved"
            type="submit"
            @click.native="checkValidity"
            :class="{ 'versionPage__saveButton--uploading': uploading }"
            class="versionPage__saveButton"
            >{{ uploading ? 'Enregistrement' : 'Enregistrer' }}</e-button
          >
          <e-link button to="/admin/versions">Annuler</e-link>
        </div>
      </header>
      <main class="versionPage__content">
        <div v-show="matchTab('settings')">
          <version-edit-form
            ref="versionEditForm"
            :available-groups="groups"
            :current-version-groups="currentVersion.groups"
            @updated="mustSave"
            class="e-content--maxWidth"
          />
          <version-upload-form
            ref="uploads"
            :attachments="currentVersion"
            @updated="saved = false"
          />
        </div>
        <version-grid
          ref="gridDesktop"
          v-show="matchTab('desktop-grid')"
          :column-count="5"
          @updated="mustSave"
          class="versionPage__gridDesktop"
        />
        <version-grid
          ref="gridMobile"
          v-show="matchTab('mobile-grid')"
          :column-count="3"
          @updated="mustSave"
          class="versionPage__gridMobile"
        />
      </main>
    </form>
    <b-popin v-if="showCancelPrompt" @cancel="hideCancelPrompt">
      <prompt-version-cancel @confirm="leaveRoute" @cancel="hideCancelPrompt" />
    </b-popin>
  </b-page>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/admin/store'
import VersionEditForm from '../../components/block/version-edit-form'
import VersionUploadForm from '../../components/block/version-upload-form'
import VersionGrid from '../../components/block/version-grid'
import PromptVersionCancel from '../../components/elements/prompt-version-cancel'
export default {
  components: {
    VersionEditForm,
    VersionGrid,
    PromptVersionCancel,
    VersionUploadForm
  },
  data() {
    return {
      saved: false,
      uploading: false,
      showCancelPrompt: false,
      leaveRoute: null
    }
  },
  computed: {
    ...mapState({
      groups: state => state.groups.list,
      currentVersion: state => state.version
    }),
    pageTitle() {
      return !this.$route.params.id
        ? 'Créer une version'
        : 'Modifier une version'
    }
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('groups/fetch')
    await store.dispatch('medias/fetch')
    if (to.params.id) {
      await store.dispatch('version/fetch', to.params.id)
      next(vm => {
        vm.saved =
          from.name === 'adminVersionsEdit' ||
          (from.name !== 'adminVersionsEdit' &&
            from.name !== 'adminVersionsCreate')
      })
    } else {
      store.dispatch('version/reset')
      next()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.showCancelPrompt = !this.saved
    if (this.showCancelPrompt) this.leaveRoute = next
    else next()
  },
  methods: {
    toTab(name) {
      return this.$route.path + `#${name}`
    },
    matchTab(name) {
      return this.$route.hash === `#${name}`
    },
    hideCancelPrompt() {
      this.showCancelPrompt = false
    },
    mustSave() {
      this.saved = false
    },
    checkValidity(e) {
      if (!this.$refs.form.checkValidity()) {
        e.stopPropagation()
        // Workaround to hide Vue DuplicatedNavigation Error on hash change
        this.$router.push({ hash: '#settings' }).catch(() => {})
      }
    },
    async saveVersion() {
      const action = this.$route.params.id ? 'updateVersion' : 'createVersion'
      try {
        this.$refs.gridDesktop.save()
        this.$refs.gridMobile.save()
        this.uploading = true

        const data = await this.$store.dispatch(
          `version/${action}`,
          this.$refs.uploads.formData
        )

        this.saved = true
        this.uploading = false

        console.log(data, this.$route)

        this.$store.dispatch('notifications/present', {
          type: 'Notice',
          text: `La version ${this.currentVersion.name} a été ${
            this.$route.params.id ? 'mise à jour' : 'créé'
          }.`
        })

        if (!this.$route.params.id) {
          this.$router.push(
            `/admin/versions/edit/${data.id}${this.$route.hash}`
          )
        }
      } catch (err) {
        this.saved = false
        this.uploading = false

        this.$store.dispatch('notifications/present', {
          type: 'Erreur',
          text: `Une erreur s'est produite: ${err.response.data.message}.`
        })
      }
    }
  }
}
// import { mapState } from 'vuex'
// // import LocomotiveScroll from 'locomotive-scroll';
// // import store from "@/admin/store";
// // import axiosAdmin from "../../scripts/axios-admin";

// import computeCoords from '@/assets/scripts/compute-grid-coords.js'

// import VersionForm from '@/admin/components/block/version-edit-form'

// import ContentGrid from '@/components/component/content-grid'
// // import ContentPopin from "@/components/component/content-popin";
// import PageFooter from '@/components/component/page-footer'
// import ASScroll from '@ashthornton/asscroll'

// // import Cookies from "js-cookie";

// export default {
//   components: {
//     ContentGrid,
//     // ContentPopin,
//     PageFooter,
//     VersionForm
//   },
//   async beforeRouteEnter(to, from, next) {
//     try {
//       //   await store.dispatch("grid/fetch");
//       next()
//     } catch (err) {
//       console.log(err)
//     }
//   },
//   data() {
//     return {
//       scroll: null
//       //   content: [],
//     }
//   },
//   computed: {
//     ...mapState({
//       content: state => state.grid.currentGrid
//     }),
//     mergedContent() {
//       return []
//       // return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
//       //   ? this.content.sizes.mobile
//       //   : this.content.sizes.desktop;
//     }
//   },
//   created() {
//     this.$bus.$on('scrollToTop', () => {
//       if (this.scroll instanceof ASScroll) {
//         this.scroll.scrollTo(0)
//       }
//     })
//   },
//   mounted() {
//     this.$store.commit('grid/init')
//     this.$store.commit('ui/initGrid')
//     if (this.$route.params.id) {
//       console.log('id', this.$route.params.id)
//       this.$store
//         .dispatch('grid/fetch', this.$route.params.id)
//         .then(response => {
//           console.log(response)
//           //   this.name = response.data.name;
//           //   this.description = response.data.description;
//         })
//         .catch(() => {
//           this.$store.dispatch('notifications/present', {
//             type: 'Error',
//             text: `La version avec l'id ${this.$route.params.id} est introuvable`
//           })
//           this.$router.push('/admin/versions')
//         })
//     }

//     this.$nextTick(() => {
//       if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) return

//       this.scroll = new ASScroll({
//         ease: 0.1
//       })
//       this.scroll.enable()

//       computeCoords()
//     })
//   },
//   methods: {
//     toggleScroll(disable = false) {
//       if (disable) {
//         if (this.scroll) this.scroll.disable()
//         else document.body.style.overflow = 'hidden'
//       } else {
//         if (this.scroll) this.scroll.enable()
//         else document.body.style.overflow = 'auto'
//       }
//     }
//   }
// }
</script>

<style lang="scss">
.versionPage {
  &__header {
    display: flex;
  }

  &__tabs {
    display: flex;
    flex-grow: 1;
    border-bottom: 1px solid var(--black);
    margin-right: var(--spacing-l);
  }

  &__tab {
    display: block;
    text-decoration: none;
    padding: var(--spacing-m);
    border: 1px solid transparent;
    margin-bottom: -1px;

    &:hover {
      font-weight: bold;
    }

    &.router-link-exact-active {
      border: 1px solid var(--black);
      border-bottom: 1px solid var(--white);
      font-weight: bold;
    }
  }

  &__actions {
    display: flex;
    align-items: flex-end;
  }

  &__content {
    padding-top: var(--spacing-xl);
  }

  &__gridMobile {
    max-width: 640px;
  }

  &__saveButton {
    display: flex;
    align-items: center;
    &::before {
      display: none;
      content: '';
      box-sizing: border-box;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      width: 10px;
      height: 10px;
      // margin-top: -10px;
      // margin-left: -10px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #000;
      animation: spinner 0.6s linear infinite;
      margin-right: var(--spacing-m);
    }

    &--uploading::before {
      display: block;
    }
  }
}
// .bookPage {
//   // @media screen and (min-width: 768px) {
//   //   margin-top: -60px;
//   // }

//   &__modale {
//     position: fixed;
//     width: 100%;
//     height: 100vh;
//     left: 0;
//     top: 0;
//     z-index: 8;
//   }

//   &,
//   &__main {
//     display: block;
//   }

//   &__main {
//     background-color: var(--white);
//     color: var(--black);
//   }
// }
</style>
